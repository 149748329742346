/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react';
import './App.css';
import CouponListTable from './Components/CouponListTable';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, collectionGroup, doc, getDoc, getDocs, query, where, orderBy, updateDoc, Timestamp, limit, startAfter, addDoc, writeBatch, collection, getCount } from 'firebase/firestore/lite';
import { get, getDatabase, ref } from 'firebase/database'; 
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import Modal from 'react-modal';

import EmailLogin from './Components/EmailLogin';

import {
    IconButton,
    Button,
    TextField,
    Card,
} from '@mui/material';


import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {styled} from 'styled-components';
import UserListTable from './Components/UserListTable';
import PushListTable from './Components/PushListTable';
import PushForm from './Components/PushForm';
import CourseListTable from './Components/CourseListTable';
import {Input} from '@mui/icons-material';
import {BrowserRouter, Link, Navigate, Route, Routes} from 'react-router-dom';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECTID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APPID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

export const HorizontalDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 16px;
`

// Initialize Firebase
export const fb_app = initializeApp(firebaseConfig);
export const firestore = getFirestore(fb_app);
export const database = getDatabase(fb_app);

const auth = getAuth(fb_app);
export const Spinner = () => {
    return (
      <div className="overlay">
        <div className="spinner"></div>
      </div>
    );
  };
const Authorization = ({
    isAuthenticated,
    redirectTo,
    children,
    props
  }) => {
    if (isAuthenticated) {
      return <>{children}</>;
    } else {
      return <Navigate to={redirectTo} />;
    }
  };
const Header = () => {
    return (
        <header>
            <Link to='/'>
                <Button>
                    쿠폰
                </Button>
            </Link>
            <Link to='/users'>
                <Button>
                    유저
                </Button>
            </Link>
            <Link to='/courses'>
                <Button>
                    코스
                </Button>
            </Link>
            {/* <Link to='/push'>
                <Button>
                    푸시
                </Button>
            </Link> */}
        </header>
    )
}
function App() {
    const [coupons, setCoupons] = useState([]);
    const [pushFormVisible, setPushFormVisible] = useState(false);
    const [loading, setLoading] = useState(true);
    
    const fetchAllCoupons = async () => {
        setLoading(true);
        try {
            console.log("fetchAllCoupons")
            const twoWeeksAgo = new Date();
            twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
            const couponsCollection = collectionGroup(firestore, 'coupons');
            const couponsQuery = query(couponsCollection, where('receivedAt', '>=', Timestamp.fromDate(twoWeeksAgo)), orderBy('receivedAt', 'dsc'));
            const querySnapshot = await getDocs(couponsQuery);
            for (const docRef of querySnapshot.docs) {
                fetchCoupon(docRef);
            } 
            
            setLoading(false);

        } catch (error) {
          console.error('Error fetching coupons:', error);
        } finally {
        }
      };
    const fetchCoupon = async (docRef) => {
        const { coupon, isCompleted, receivedAt, type } = docRef.data();
        const itemDocRef = doc(firestore, type === 'claw' ? 'items_claw' : 'items_coupon', coupon);
        const itemDocSnap = await getDoc(itemDocRef);
        if (itemDocSnap.exists()) {
            const { name } = itemDocSnap.data();
            const fireBaseTime = new Date(
                receivedAt.seconds * 1000 + receivedAt.nanoseconds / 1000000,
            );
            const date = fireBaseTime.toLocaleDateString("ko-KR");
            const atTime = fireBaseTime.toLocaleTimeString();
            const uid = docRef.ref.parent.parent.id;
            let numInvitations, numCoupons, daysAppUse, userCash;
            let invitationData;
            const userDocRef = doc(firestore, 'users', uid);
            const userDocSnap = await getDoc(userDocRef);
            const userRef = ref(database, `users/${uid}/userInfo`);
            const userSnapshot = await get(userRef);
            if(userSnapshot.exists()) {
                userCash = userSnapshot.val().cash;
            }
            if (userDocSnap.exists()) {
                const { phonenumber } = userDocSnap.data();
                try {
                    const couponsRef = collection(firestore, `users/${uid}/coupons`);
                    const chancesRef = collection(firestore, `users/${uid}/chances`);
                    const invitationCollection = collection(firestore, `invitations`);
                    const invitationQuery = query(invitationCollection, where('phonenumber', '==', phonenumber));
                    const couponSnapshot = await getCount(couponsRef);
                    const chancesSnapshot = await getCount(chancesRef);
                    const invitatinoSnapshot = await getDocs(invitationQuery);
                    invitationData = invitatinoSnapshot.docs[0].data();
                    const {guests, guests2} = invitationData;
                    numInvitations = (guests?.length || 0) + (guests2?.length || 0);
                    numCoupons = couponSnapshot.data().count;
                    daysAppUse = chancesSnapshot.data().count;
                } catch(err) {
                    console.log(err)
                }
                setCoupons((prevCoupons) => {
                    const newData = {
                        id: docRef.id,
                        uid: uid,
                        phonenumber: phonenumber,
                        coupon_name: name,
                        user_cash: userCash,
                        num_invitations: numInvitations,
                        num_coupons: numCoupons,
                        days_appuse: daysAppUse,
                        receivedAt: date + ' ' + atTime,
                        isCompleted,
                        invitationData: invitationData
                    };
                    const sortedCouponsData = [...prevCoupons, newData].sort((a, b) => {
                        // Use localeCompare for case-insensitive string comparison
                        return a.coupon_name.localeCompare(b.coupon_name);
                    });
                    return sortedCouponsData
                });
            }
        }
    }
    
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, user => {
            if(auth.currentUser && (auth.currentUser.email === 'charlie@nmax.com' || auth.currentUser.email === 'peter@nmax.com')) {
                fetchAllCoupons();
            }
            
        })
        return () => {
            auth.signOut();
            unsubscribe();
        }
    }, []);
    const onUpdateIsCompleted = async (uid, id, val) => {
        try {
          // Update the isCompleted field in Firestore
          const couponDocRef = doc(firestore, `users/${uid}/coupons`, id); // Replace with your collection name
          await updateDoc(couponDocRef, {
            isCompleted: val,
          });
    
          // Update the state to reflect the change
          const updatedCoupons = coupons
          .map((coupon) => {
            if (coupon.id === id) {
              coupon.isCompleted = val;
              coupon.completedAt = Timestamp.now();
            }
            return coupon;
          })
          setCoupons(updatedCoupons);
          
          console.log(`Coupon with ID ${id} marked as completed.`);
        } catch (error) {
          console.error('Error updating coupon:', error);
        }
      };
      const onLogin = async (email, password) => {
        setLoading(true);
        try {
            await signInWithEmailAndPassword(auth, email, password);
        } catch(err) {
            console.log(err.code)
            if(err.code === 'auth/invalid-email') {
                window.alert('올바르지 않은 이메일입니다.')
            }
            else if(err.code === 'auth/user-not-found') {
                window.alert('유저를 찾을 수 없습니다.')
            } 
            else if(err.code === 'auth/wrong-password') {
                window.alert('잘못된 비밀번호입니다.')
            }
            
        } finally {
            setLoading(false);
        }
      }
      const pushSend = async (title, contents) => {
        console.log(title, contents)
        const URL = "https://ulwf72dp6es7z5vson6o2z5mwi0jmmds.lambda-url.ap-northeast-2.on.aws/push";
        // const URL = "http://192.168.115.2:3001/push";

        try {
            setLoading(true);
            const response = await fetch(URL, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ title: title, contents: contents }),
            });
            console.log(response.status)
            if (response.ok) {
                console.log(response.text)
            } else {
              console.error('Error:', response.status, response.statusText);
              return null;
            }
        } catch (error) {
            console.error('Error:', error);
            alert("푸시 알림 전송 실패!")
            return null;
        } finally {
            setLoading(false);
        }

      }
      
    return (
        <BrowserRouter>
            <Routes>
                <Route 
                path="/login"
                element={
                    <EmailLogin 
                    isAuthenticated={auth.currentUser && (auth.currentUser.email === 'charlie@nmax.com' || auth.currentUser.email === 'peter@nmax.com')}
                    onLogin={onLogin}/>
                }
                />
                <Route
                path="/"
                element={
                    <Authorization
                    isAuthenticated={auth.currentUser && (auth.currentUser.email === 'charlie@nmax.com' || auth.currentUser.email === 'peter@nmax.com')}
                    redirectTo='/login'
                    location={'/'}
                    >
                        <Header/>
                        <HorizontalDiv>
                            <h1>쿠폰 지급 신청 목록</h1>
                        </HorizontalDiv>
                        <CouponListTable coupons={coupons} onUpdateIsCompleted={onUpdateIsCompleted} showCompleted={false}/>
                        <HorizontalDiv>
                            <h1>쿠폰 지급 완료 목록</h1>
                        </HorizontalDiv>
                        <CouponListTable coupons={coupons} onUpdateIsCompleted={onUpdateIsCompleted} showCompleted={true}/>
                    </Authorization>
                }
                />
                {/* <Route
                path="/push"
                element={
                    <Authorization
                    isAuthenticated={auth.currentUser && (auth.currentUser.email === 'charlie@nmax.com' || auth.currentUser.email === 'peter@nmax.com')}
                    redirectTo='/login'
                    location={'/'}
                    >
                        <PushForm isOpen={pushFormVisible} onClose={() => setPushFormVisible(false)} onSend={pushSend}/>
                        <HorizontalDiv>
                            <h1>푸시 알림 목록</h1>
                        </HorizontalDiv>
                    </Authorization>    
                }
                /> */}
                <Route
                path="/courses"
                element={
                    <Authorization
                    isAuthenticated={auth.currentUser && (auth.currentUser.email === 'charlie@nmax.com' || auth.currentUser.email === 'peter@nmax.com')}
                    redirectTo='/login'
                    location={'/'}
                    >
                        <Header/>
                        <HorizontalDiv>
                            <h1>코스 목록</h1>
                        </HorizontalDiv>
                        <CourseListTable/>
                    </Authorization>    
                }
                />
                <Route
                exact
                path="/users"
                element={
                    <Authorization
                    isAuthenticated={auth.currentUser && (auth.currentUser.email === 'charlie@nmax.com' || auth.currentUser.email === 'peter@nmax.com')}
                    redirectTo='/login'
                    location={'/'}
                    >
                        <Header/>
                        <HorizontalDiv>
                            <h1>유저 목록</h1>
                        </HorizontalDiv>
                        <UserListTable/>
                    </Authorization>    
                }
                />
                <Route
                exact
                path="/users/:id"
                element={
                    <Authorization
                    isAuthenticated={auth.currentUser && (auth.currentUser.email === 'charlie@nmax.com' || auth.currentUser.email === 'peter@nmax.com')}
                    redirectTo='/login'
                    location={'/'}
                    >
                        <Header/>
                        <HorizontalDiv>
                            <h1>유저</h1>
                        </HorizontalDiv>
                    </Authorization>    
                }
                />
            </Routes>
        </BrowserRouter>
                 
        // <div className="App">
        // {loading && <Spinner />} {/* Show spinner when isLoading is true */}
        
        // </div>
    );
}

export default App;
