import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TextField,
  Button,
} from '@mui/material';
import Box from '@mui/material/Box';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchIcon from '@mui/icons-material/Search';
import {HorizontalDiv, database, firestore} from '../App';
import { collection, doc, getDoc, getDocs, where, getCount } from 'firebase/firestore/lite';

import { ref, get, limitToLast, query, orderByChild, endBefore } from 'firebase/database';
import {Navigate, useNavigate} from 'react-router';
const UserListTable = () => {
  const [sortKey, setSortKey] = useState(null); // Track the sorting key
  const [sortOrder, setSortOrder] = useState('asc'); // Track sorting order
  const [lastCashValue, setLastCashValue] = useState(null); // Store the last "cash" value
  const [users, setUsers] = useState([]);
  const [interestedUser, setInterestedUser] = useState(null);
  const [searchString, setSearchString] = useState("");
  const navigate = useNavigate();
  const handleMouseOver = (index) => {
    setInterestedUser(index);
  }
  const handleMouseOut = () => {
    setInterestedUser(null);
  }
  const selectUser = (index) => {
    // navigate(`/users/${users[index].id}`)
    // navigator.clipboard.writeText(users[index].id)
    // window.alert("유저 ID가 복사되었습니다.")
    if(window.confirm('해당 유저의 페이지로 이동하시겠습니까?')) {
        window.open(`https://console.firebase.google.com/u/0/project/cashball-dd7e8/firestore/data/users/${users[index].id}?hl=ko`)
    }
  }
  useEffect(() => {
    fetchUsers();
  }, [])
  const searchUsers = async (value) => {
    if(value) {
        try {
            const usersCollection = collection(firestore, `users`);
            const userDocRef = doc(firestore, 'users', value);
            const userSnap = await getDoc(userDocRef)
            if(userSnap.exists()) {
                const data = userSnap.data();
                const id = value;
                const couponsRef = collection(firestore, `users/${id}/coupons`);
                const chancesRef = collection(firestore, `users/${id}/chances`);
                let nickname;
                let phonenumber;
                let count;
                let count2;
                let exp;

                if(data) { 
                    if(data.nickname) {
                        nickname = data.nickname;
                    }
                    if(data.phonenumber) {
                        phonenumber = data.phonenumber;
                    }
                    if(data.exp) {
                        exp = data.exp;
                    }
                }
                const usersRef = ref(database, `users/${id}`);
                const snapshot = await get(usersRef);
                if(snapshot.exists()) {
                    const {userInfo, lastLogin} = snapshot.val();
                    try {
                        const couponSnapshot = await getCount(couponsRef);
                        const chancesSnapshot = await getCount(chancesRef);
                        count = couponSnapshot.data().count;
                        count2 = chancesSnapshot.data().count;
                    } catch(err) {
                        console.log(err)
                    }
                    const userData = {
                        id, 
                        cash: userInfo.cash,
                        chances: userInfo.chances,
                        lastLogin: lastLogin ? lastLogin.loginAt : null,
                        appVersion: lastLogin ? lastLogin.appVersion : null,
                        nickname: nickname || "",
                        phonenumber: phonenumber || "",
                        exp: exp || 0,
                        count: count || 0,
                        count2: count2 || 0, 
                    }
                    setUsers([userData]);
                }
    
            } else {
                const promises = [];
                const usersQuery = query(usersCollection, where('nickname', ">=", value), where('nickname', "<=", value))
                const querySnapshot = await getDocs(usersQuery);
                querySnapshot.forEach((docSnap) => {
                    const id = docSnap.id
                    const couponsRef = collection(firestore, `users/${id}/coupons`);
                    const chancesRef = collection(firestore, `users/${id}/chances`);
                    const data = docSnap.data();
                    let nickname;
                    let phonenumber;
                    let count;
                    let count2;
                    let exp;

                    if(data) { 
                        if(data.nickname) {
                            nickname = data.nickname;
                        }
                        if(data.phonenumber) {
                            phonenumber = data.phonenumber;
                        }
                        if(data.exp) {
                            exp = data.exp;
                        }
                    }
                    const usersRef = ref(database, `users/${id}`);
                    promises.push(get(usersRef).then(async (snapshot) => {
                        if(snapshot.exists()) {
                            const {userInfo, lastLogin} = snapshot.val();
                            try {
                                const couponSnapshot = await getCount(couponsRef);
                                const chancesSnapshot = await getCount(chancesRef);
                                count = couponSnapshot.data().count;
                                count2 = chancesSnapshot.data().count;
                            } catch(err) {
                                console.log(err)
                            }
                            return {
                                id, 
                                cash: userInfo.cash,
                                chances: userInfo.chances,
                                lastLogin: lastLogin ? lastLogin.loginAt : null,
                                appVersion: lastLogin ? lastLogin.appVersion : null,
                                nickname: nickname || "",
                                phonenumber: phonenumber || "",
                                exp: exp || 0,
                                count: count || 0,
                                count2: count2 || 0, 
                            }
                        }
                    }));
                })
                const newData = await Promise.all(promises)
                setUsers(newData);
                        // const usersRef = ref(database, 'users');
            }
        } catch (error) {
          console.error('Error searching users:', error);
        }
    } else {
        fetchUsers();
    }
  }
  const fetchUsers = async () => {

    try {
        const usersRef = ref(database, 'users');
        const userData = [...users]
        // Order the query by the cash field in descending order
        let userQuery = null;
        if(lastCashValue)
            userQuery = query(usersRef, orderByChild('userInfo/cash'), limitToLast(50), endBefore(lastCashValue));
        else
            userQuery = query(usersRef, orderByChild('userInfo/cash'), limitToLast(50));
        const snapshot = await get(userQuery);
        const userPromises = [];
        snapshot.forEach((child) => {
            const {userInfo, lastLogin} = child.val();
            const userDocRef = doc(firestore, 'users', child.key);
            const couponsRef = collection(firestore, `users/${child.key}/coupons`);
            const chancesRef = collection(firestore, `users/${child.key}/chances`);
            const userPromise = getDoc(userDocRef).then(async (userDocSnapshot) => {
                const data = userDocSnapshot.data();
                let nickname;
                let phonenumber;
                let exp;
                let count;
                let count2;
                if(data) { 
                    if(data.nickname) {
                        nickname = data.nickname;
                    }
                    if(data.phonenumber) {
                        phonenumber = data.phonenumber;
                    }
                    if(data.exp) {
                        exp = data.exp;
                    }
                    try {
                        const couponSnapshot = await getCount(couponsRef);
                        const chancesSnapshot = await getCount(chancesRef);
                        count = couponSnapshot.data().count;
                        count2 = chancesSnapshot.data().count;
                    } catch(err) {
                        console.log(err)
                    }
                }
                return {
                    id: child.key,
                    cash: userInfo.cash,
                    chances: userInfo.chances,
                    lastLogin: lastLogin ? lastLogin.loginAt : null,
                    appVersion: lastLogin ? lastLogin.appVersion : null,
                    nickname: nickname || "",
                    phonenumber: phonenumber || "",
                    exp: exp || 0,
                    count: count || 0,
                    count2: count2 || 0, 
                }
            });
            
            userPromises.push(userPromise);
        })
        const newData = await Promise.all(userPromises);
          // Reverse the array to get descending order
        const updatedUserData = [...userData, ...newData]
        const sortedUserData = updatedUserData.sort((a,b) => b.cash - a.cash);
        const filteredUserData = sortedUserData.filter((user) => user.nickname !== "");
        setUsers(filteredUserData);
        setLastCashValue(filteredUserData[filteredUserData.length-1].cash)
    } catch (error) {
      console.error('Error fetching users:', error);
    }
}
  const handleSort = (key) => {
    if (key === sortKey) {
        // If the same key is clicked, toggle the sorting order
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
        // If a different key is clicked, set it as the new sorting key and default to ascending order
        setSortKey(key);
        setSortOrder('asc');
    }
  };
  const sortedUsers = [...users].sort((a, b) => {
    if (sortKey) {
      if (sortOrder === 'asc') {
        return a[sortKey] - b[sortKey];
      } else {
        return b[sortKey] - a[sortKey];
      }
    }
    return 0;
  });
  const handleSubmitSearch = (event) => {
    event.preventDefault();
    searchUsers(searchString)
  }
  return (
    <TableContainer component={Paper}>
        <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmitSearch}
        >
        <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        columnGap: 16,
        padding: 16,
      }}>
          <SearchIcon/>
          <TextField id="outlined-search" label="User ID / 닉네임" type="search" value={searchString} onChange={(event) => setSearchString(event.target.value)} onKeyUp={(event) => {if(event.key === 'Enter') handleSubmitSearch(event)}}/>
          <Button
          type="submit"
          variant="contained" color="primary">
            검색
          </Button>
      </div>
        </Box>
      
      <Table aria-label="User List">
      <TableHead>
          <TableRow>
            <TableCell>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                Index
              </div>
            </TableCell>
            <TableCell>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                User ID
                <IconButton onClick={() => handleSort('id')}>
                  {sortKey === 'userId' && sortOrder === 'asc' ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </IconButton>
              </div>
            </TableCell>
            <TableCell>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                닉네임
                <IconButton onClick={() => handleSort('nickname')}>
                  {sortKey === 'nickname' && sortOrder === 'asc' ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </IconButton>
              </div>
            </TableCell>
            <TableCell>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                휴대폰 번호
                <IconButton onClick={() => handleSort('phonenumber')}>
                  {sortKey === 'phonenumber' && sortOrder === 'asc' ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </IconButton>
              </div>
            </TableCell>
            <TableCell>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                캐시
                <IconButton onClick={() => handleSort('cash')}>
                  {sortKey === 'cash' && sortOrder === 'asc' ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </IconButton>
              </div>
            </TableCell>
            <TableCell>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                캐시볼
                <IconButton onClick={() => handleSort('chances')}>
                  {sortKey === 'chances' && sortOrder === 'asc' ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </IconButton>
              </div>
            </TableCell>
            <TableCell>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                경험치
              </div>
            </TableCell>
            <TableCell>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                구매쿠폰수
              </div>
            </TableCell>
            <TableCell>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                앱이용일
              </div>
            </TableCell>
            <TableCell>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                최근 로그인
              </div>
            </TableCell>
            <TableCell>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                앱 버전
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody onMouseOut={handleMouseOut}>
          {sortedUsers.map((user, index) => (
            <TableRow key={user.id} onMouseOver={(event) => handleMouseOver(index)}
              onClick={() => selectUser(index)}
              style={{
                cursor: 'pointer',
                backgroundColor: interestedUser === index ? '#E4EDF1' : 'transparent'
              }}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{user.id}</TableCell>
              <TableCell>{user.nickname}</TableCell>
              <TableCell>{user.phonenumber}</TableCell>
              <TableCell>{user.cash}</TableCell>
              <TableCell>{user.chances}</TableCell>
              <TableCell>{user.exp}</TableCell>
              <TableCell>{user.count}</TableCell>
              <TableCell>{user.count2}</TableCell>
              <TableCell>{user.lastLogin}</TableCell>
              <TableCell>{user.appVersion}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <HorizontalDiv>
        {users.length > 1 &&
        <div style={{paddingTop: 16, paddingBottom: 16}}>
            <Button 
            variant="contained" color="primary"
            onClick={fetchUsers} style={{fontSize: 16}}>50명 더 불러오기</Button>
        </div>
        }
      </HorizontalDiv>
    </TableContainer>
  );
};

export default UserListTable;
