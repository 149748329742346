import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import {styled} from 'styled-components';
const CenterDiv = styled.div`
    align-items: center;
    justify-content: center;
    padding-top: 16px;
    padding-bottom: 16px;
`
const PushListTable = ({pushes}) => {

  return (
    <TableContainer component={Paper}>
      {pushes.length > 0 ?
      <Table aria-label="User List">
      <TableHead>
          <TableRow>
            <TableCell>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                Index
              </div>
            </TableCell>
            <TableCell>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                Created At
              </div>
            </TableCell>
            <TableCell>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                제목
              </div>
            </TableCell>
            <TableCell>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                내용
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pushes.map((push, index) => (
            <TableRow key={index}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{push.createdAt}</TableCell>
              <TableCell>{push.title}</TableCell>
              <TableCell>{push.contents}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    :
    <CenterDiv>
    목록이 비어있습니다.
    </CenterDiv>
    }
    </TableContainer>
  );
};

export default PushListTable;
