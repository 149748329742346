import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
} from '@mui/material';
import {styled} from 'styled-components';
import Modal from 'react-modal';
import CloseIcon from '@mui/icons-material/Close';
import {collection, getDocs, orderBy, query, where} from 'firebase/firestore/lite';
import {firestore} from '../App';

const CenterDiv = styled.div`
    align-items: center;
    justify-content: center;
    padding-top: 16px;
    padding-bottom: 16px;
`
const CouponListTable = ({ coupons, onUpdateIsCompleted, showCompleted }) => {
    const filteredCoupons = showCompleted
      ? coupons.filter((coupon) => coupon.isCompleted)
      : coupons.filter((coupon) => !coupon.isCompleted);
    
    const [cashModalVisible, setCashModalVisible] = useState(false);
    const [invitationModalVisible, setInvitationModalVislble] = useState(false);
    const [selectedUser, setSelectedUser] = useState("")
    const [cashHistory, setCashHistory] = useState({});
    const [invitationList, setInvitationList] = useState([]);
    const viewCashHistory = (uid) => {
        setSelectedUser(uid);
        fetchCashHistory(uid);
        setCashModalVisible(true);
    }
    const fetchCashHistory = async (uid) => {
        const userCol = collection(firestore, `users/${uid}/historyList`);
        const today = new Date();
        const tenDaysAgo = new Date(today.setDate(today.getDate() - 10));
        tenDaysAgo.setHours(0, 0, 0, 0);
        const historyQuery = query(userCol, where('createdAt', '>=', tenDaysAgo), orderBy('createdAt', 'dsc'));
        const historySnapshot = await getDocs(historyQuery);
        const dataByDate = {};
        for(const doc of historySnapshot.docs) {
            const {createdAt, params} = doc.data();
            const date = createdAt.toDate().toISOString().split('T')[0];
            if (!dataByDate[date]) {
                dataByDate[date] = params?.rewardCash || 0;
            } else {
                dataByDate[date] += params?.rewardCash || 0;
            }
        }
        setCashHistory(dataByDate);
    }
    const viewInvitations = (uid, invitationData) => {
        setSelectedUser(uid);
        const {guests, guests2} = invitationData;
        if(guests) {
            for(const guest of guests) {
                getUserInfoByPN(guest)
            }
        }
        if(guests2) {
            for(const guest of guests2) {
                getUserInfoByPN(guest)
            }
        }
        setInvitationModalVislble(true);
    }
    const getUserInfoByPN = async (phonenumber) => {
        const userCol = collection(firestore, 'users');
        const userQuery = query(userCol, where('phonenumber', '==', phonenumber));
        const querySnapshot = await getDocs(userQuery);
        if(!querySnapshot.empty) {
            const {createdAt, birthdate, account} = querySnapshot.docs[0].data();
            setInvitationList((prev) => {
                const newData = {
                    uid: querySnapshot.docs[0].id,
                    phonenumber, createdAt, birthdate, account
                };
                return [...prev, newData]
            })
        }
        
    }
    const closeCashModal = () => {
        setCashHistory([]);
        setSelectedUser("");
        setCashModalVisible(false);
    }
    const closeInvitationModal = () => {
        setInvitationList([]);
        setSelectedUser("");
        setInvitationModalVislble(false);
    }
    const renderCashHistory = () => {
        const cells = []
        for(const date in cashHistory) {
            cells.push({
                date, 
                cash: cashHistory[date]
            })
        }
        
        return (
        <TableBody>
            {cells.map((item) => {
            return (
                <TableRow>
                    <TableCell>{item.date}</TableCell>
                    <TableCell>{item.cash}</TableCell>
                </TableRow>
            )})}
        </TableBody>
        )
    }
    const clickUserId = () => {
        if(window.confirm('해당 유저의 페이지로 이동하시겠습니까?')) {
            window.open(`https://console.firebase.google.com/u/0/project/cashball-dd7e8/firestore/data/users/${selectedUser}?hl=ko`)
        }
      }
  return (
    <TableContainer component={Paper}>
      {filteredCoupons.length > 0 ?
      <Table aria-label="Coupon List">
        <TableHead>
          <TableRow>
            <TableCell>쿠폰명</TableCell>
            <TableCell>휴대폰 번호</TableCell>
            <TableCell>보유 캐시량</TableCell>
            <TableCell>초대 인원수</TableCell>
            <TableCell>구매 쿠폰수</TableCell>
            <TableCell>앱 이용일</TableCell>
            <TableCell>구매일</TableCell>
            <TableCell>Action</TableCell> {/* Added column for actions */}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredCoupons.map((coupon, index) => (
            <TableRow key={coupon.id}>
              <TableCell>{coupon.coupon_name}</TableCell>
              <TableCell>{coupon.phonenumber}</TableCell>
              <TableCell style={{cursor: 'pointer'}} onClick={() => viewCashHistory(coupon.uid)}>{coupon.user_cash}</TableCell>
              <TableCell style={{cursor: 'pointer'}} onClick={() => viewInvitations(coupon.uid, coupon.invitationData)}>{coupon.num_invitations}</TableCell>
              <TableCell>{coupon.num_coupons}</TableCell>
              <TableCell>{coupon.days_appuse}</TableCell>
              <TableCell>{coupon.receivedAt}</TableCell>
              <TableCell>
                {/* Button to mark the coupon as completed */}
                {!coupon.isCompleted ?
                    <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onUpdateIsCompleted(coupon.uid, coupon.id, true)}
                  >
                    지급 완료
                  </Button>
                  :
                  <Button
                  variant="contained"
                  color="primary"
                  onClick={() => onUpdateIsCompleted(coupon.uid, coupon.id, false)}
                  >
                    되돌리기
                  </Button>
                }
                
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      : 
      <CenterDiv>
          목록이 비어있습니다.
      </CenterDiv>
      }
      <Modal isOpen={invitationModalVisible}>
            <IconButton onClick={closeInvitationModal} className="close-button">
                <CloseIcon/>
            </IconButton>
            <h1 style={{cursor:'pointer'}} onClick={clickUserId}>{selectedUser}</h1>
            <div>
                <Table aria-label="초대자 리스트">
                    <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>휴대폰 번호</TableCell>
                        <TableCell>가입일</TableCell>
                        <TableCell>생년월일</TableCell>
                        <TableCell>이메일</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {invitationList.map((item) => (
                        <TableRow key={item.uid}>
                        <TableCell>{item.uid}</TableCell>
                        <TableCell>{item.phonenumber}</TableCell>
                        <TableCell>{item.createdAt?.toDate().toISOString()}</TableCell>
                        <TableCell>{item.birthdate}</TableCell>
                        <TableCell>{item.account.email}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </div>
        </Modal>
      <Modal isOpen={cashModalVisible}>
            <IconButton onClick={closeCashModal} className="close-button">
                <CloseIcon/>
            </IconButton>
            <h1 style={{cursor:'pointer'}} onClick={clickUserId}>{selectedUser}</h1>
            <div>
                <Table aria-label="날짜별 캐시 획득 내역">
                    <TableHead>
                    <TableRow>
                        <TableCell>날짜</TableCell>
                        <TableCell>획득 캐시</TableCell>
                    </TableRow>
                    </TableHead>
                    {renderCashHistory()}
                </Table>
            </div>
        </Modal>
    </TableContainer>
  );
};

export default CouponListTable;
