// EmailLogin.js
import {TextField, Button} from '@mui/material';
import React, { useState } from 'react';
import {Navigate} from 'react-router';

const EmailLogin = ({ isAuthenticated, onLogin, location }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = () => {
    onLogin(email, password);
  };
  const { from } = location || { from: { pathname: "/" } };

  if(isAuthenticated) {
    return (
        <Navigate to={from}/>
    )
  }
  return (
    <div style={{
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        flexDirection: 'column'
    }}>
      <h2>로그인</h2>
      <div style={{
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        flexDirection: 'column',
        rowGap: 16,
    }}>
        <TextField id="standard-email" fullWidth label="Email" variant="standard" value={email} onChange={(e) => setEmail(e.target.value)}/>
        <TextField id="standard-pass" fullWidth label="Password" variant="standard" type="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
        <Button variant="contained" color="primary" onClick={handleLogin} disabled={email==="" || password===""}>Login</Button>
      </div>
    </div>
  );
};

export default EmailLogin;
