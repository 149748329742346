import React, { useState } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // Set the root element as the app element

const PushForm = ({ isOpen, onClose, onSend }) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  const handleSend = async () => {
    await onSend(title, content);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} 
    style={{
        display: 'flex',
        width: 500, height: 500
    }}>
      <button onClick={onClose} className="close-button">
        X
      </button>
      <h2>푸시 알림 생성</h2>
      <input
        type="text"
        placeholder="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <input
        type="text"
        placeholder="Content"
        value={content}
        onChange={(e) => setContent(e.target.value)}
      />
      <button onClick={handleSend} disabled={title.length === 0}>Send</button>
    </Modal>
  );
};

export default PushForm;
