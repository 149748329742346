/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useCallback, useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  TextField,
} from '@mui/material';
import Modal from 'react-modal';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import MusicOffIcon from '@mui/icons-material/MusicOff';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { getFirestore, collection, doc, getDocs, query, orderBy, addDoc, updateDoc, writeBatch } from 'firebase/firestore/lite';
import { getStorage, ref, deleteObject, getDownloadURL, uploadBytes } from 'firebase/storage';

import {HorizontalDiv, Spinner, fb_app} from '../App';

const CourseListTable = () => {
    const [courses, setCourses] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [selectedTopic, setSelectedTopic] = useState(null);
    const [topics, setTopics] = useState([]);
    const [interestedSentence, setInterestedSentence] = useState(null);
    const [selectedSentence, setSelectedSentence] = useState(null);
    const [selectedSentenceData, setSelectedSentenceData] = useState(null);
    const [changedSentences, setChangedSentences] = useState([]);
    const [loading, setLoading] = useState(false);
    const [addCourseModalVisible, setAddCourseModalVisible] = useState(false);
    const [uploadIntroModalVisible, setUploadIntroModalVisible] = useState(false);
    const [uploadingIntroCourse, setUploadingIntroCourse] = useState("");
    const [introFile, setIntroFile] = useState(null);
    const [newCourseData, setNewCourseData] = useState(null);
    const [newCourseInfo, setNewCourseInfo] = useState({
        title: "",
        description: "",
        level: null,
        code: "",
        theme: "",
        size: 0,
        is_new: true,
    });
    const [levelEditingIndex, setLevelEditingIndex] = useState(-1);
    const [themeEditingIndex, setThemeEditingIndex] = useState(-1);
    const handleEditingLevel = (event) => {
        if(event.key === 'Enter') {
            if(event.target.value) {
                const arr = [...courses]
                if(event.target.value !== arr[levelEditingIndex].level) {
                    arr[levelEditingIndex].level = event.target.value;
                    setCourses(arr);
                    const firestore = getFirestore(fb_app);
                    const coursesCollection = collection(firestore, 'courses');
                    const docRef = doc(coursesCollection, arr[levelEditingIndex].id);    
                    updateDoc(docRef, {
                        level: event.target.value
                    })
                }
                
            }
            setLevelEditingIndex(-1);
        }
        else if(event.key === 'Escape') {
            setLevelEditingIndex(-1);
        }
    }
    const handleEditingTheme = (event) => {
        if(event.key === 'Enter') {
            if(event.target.value) {
                const arr = [...courses]
                if(event.target.value !== arr[themeEditingIndex].theme) {
                    arr[themeEditingIndex].theme = event.target.value;
                    setCourses(arr);
                    const firestore = getFirestore(fb_app);
                    const coursesCollection = collection(firestore, 'courses');
                    const docRef = doc(coursesCollection, arr[levelEditingIndex].id);    
                    updateDoc(docRef, {
                        theme: event.target.value
                    })
                }
                
            }
            setThemeEditingIndex(-1);
        }
        if(event.key === 'Escape') {
            setThemeEditingIndex(-1);
        }
    }
    const handleIntroFileChange = (event) => {
        const file = event.target.files[0];
        setIntroFile(file);
    } 
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const csvData = e.target.result;
                const csvArray = CSVToArray(csvData, ",");
                processData(csvArray);
            };

            reader.readAsText(file);
        }
    };
    
    const CSVToArray = (strData, strDelimiter) => {
        // Check to see if the delimiter is defined. If not,
        // then default to comma.
        strDelimiter = (",");
      
        // Create a regular expression to parse the CSV values.
        var objPattern = new RegExp(
          (
            // Delimiters.
            "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +
      
            // Quoted fields.
            "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
      
            // Standard fields.
            "([^\"\\" + strDelimiter + "\\r\\n]*))"
          ),
          "gi"
        );
      
      
        // Create an array to hold our data. Give the array
        // a default empty first row.
        var arrData = [[]];
      
        // Create an array to hold our individual pattern
        // matching groups.
        var arrMatches = null;
      
      
        // Keep looping over the regular expression matches
        // until we can no longer find a match.
        while (arrMatches = objPattern.exec(strData)) {
      
          // Get the delimiter that was found.
          var strMatchedDelimiter = arrMatches[1];
      
          // Check to see if the given delimiter has a length
          // (is not the start of string) and if it matches
          // field delimiter. If id does not, then we know
          // that this delimiter is a row delimiter.
          if (
            strMatchedDelimiter.length &&
            strMatchedDelimiter !== strDelimiter
          ) {
      
            // Since we have reached a new row of data,
            // add an empty row to our data array.
            arrData.push([]);
      
          }
      
          var strMatchedValue;
      
          // Now that we have our delimiter out of the way,
          // let's check to see which kind of value we
          // captured (quoted or unquoted).
          if (arrMatches[2]) {
      
            // We found a quoted value. When we capture
            // this value, unescape any double quotes.
            strMatchedValue = arrMatches[2].replace(
              new RegExp("\"\"", "g"),
              "\""
            );
      
          } else {
      
            // We found a non-quoted value.
            strMatchedValue = arrMatches[3];
      
          }
      
      
          // Now that we have our value string, let's add
          // it to the data array.
          arrData[arrData.length - 1].push(strMatchedValue);
        }
      
        // Return the parsed data.
        return (arrData);
      }
    const processData = (data) => {
        const newData = [];
        let sentences = []
        data.forEach((item, index) => {
            if(index === 0) return;
            const sentence = {}
            sentence[data[0][0]] = item[0]
            sentence[data[0][1]] = item[1]
            sentence[data[0][2]] = item[2]
            sentence[data[0][3]] = item[3]
            sentences.push(sentence)
        })
        let currentTopic = "";
        let currentIndex = -1;
        sentences.forEach((sentence) => {
            if (sentence.topic !== currentTopic) {
                currentTopic = sentence.topic;
                currentIndex++;
                newData.push({
                    index: currentIndex,
                    topic: sentence.topic,
                    sentences: []
                });
            }
            newData[currentIndex].sentences.push({
                eng: sentence.eng,
                kor: sentence.kor,
                pro_kor: sentence.pro_kor
            });
        })
        setNewCourseData(newData.filter((data) => data.topic !== ""));
        setNewCourseInfo({
            ...newCourseInfo,
            size: sentences.length
        })
    }
    const fetchCourse = useCallback(async (docRef) => {
        const storage = getStorage(fb_app);
        const id = docRef.id;
        let url;
        
        try {
            url = await getDownloadURL(ref(storage, `audios/intro/${docRef.id}.mp3`));
        } catch(err) {
    
        }
        const newData = {id, url, ...docRef.data()};
        setCourses((prev) => {
            return [...prev, newData].sort((a, b) => a.index - b.index);
        })
    }, []);
      const fetchCourses = useCallback(async () => {
        const firestore = getFirestore(fb_app);
        const coursesCollection = collection(firestore, 'courses');
        const coursesQuery = query(coursesCollection, orderBy('index', 'asc'));
        const querySnapshot = await getDocs(coursesQuery);
        for (const docRef of querySnapshot.docs) {
            fetchCourse(docRef)
        }
    }, [fetchCourse]);
  useEffect(() => {
    if(courses.length === 0)
        fetchCourses()
  }, [courses, fetchCourses])
  

const handleChangeCourseIndex = async (index, direction) => {
    const firestore = getFirestore(fb_app);
    if(direction === 'up') {
        setLoading(true);
        const coursesCollection = collection(firestore, 'courses');
        const batch = writeBatch(firestore);
        const arr = [...courses];
        let temp = arr[index];
        arr[index] = arr[index - 1];
        arr[index-1] = temp;
        let _index = 0;
        for(const item of arr) {
            const docRef = doc(coursesCollection, item.id);    
            batch.update(docRef, {
                index: _index
            })
            _index++

        }
        setCourses(arr);
        await batch.commit();
        setLoading(false);
    } 
    else if(direction === 'down') {
        setLoading(true);
        const coursesCollection = collection(firestore, 'courses');
        const batch = writeBatch(firestore);
        const arr = [...courses];
        let temp = arr[index];
        arr[index] = arr[index + 1];
        arr[index+1] = temp;
        let _index = 0;
        for(const item of arr) {
            const docRef = doc(coursesCollection, item.id);    
            batch.update(docRef, {
                index: _index
            })
            _index++
        }
        setCourses(arr);
        await batch.commit();
        setLoading(false);
    }
  }
  const addCourse = async () => {
    const firestore = getFirestore(fb_app);
    setLoading(true);
    const collectionRef = collection(firestore, 'courses')
    const docs = await getDocs(collectionRef);

    const docRef = await addDoc(collectionRef, {
        index: docs.size,
        ...newCourseInfo,
    });
    const topicsCollectionRef = collection(firestore, `courses/${docRef.id}/topics`);
    const promises = [];
    newCourseData.forEach((course) => {
        promises.push(addDoc(topicsCollectionRef, course));
    })
    await Promise.all(promises);
    setLoading(false);
    if(window.confirm('코스 추가가 완료되었습니다.')) {
        closeAddCourseModal();
    }
}
const handleCourseTitleChanged = (event) => {
    setNewCourseInfo({
        ...newCourseInfo,
        title: event.target.value
    })
}
const handleCourseDescChanged = (event) => {
    setNewCourseInfo({
        ...newCourseInfo,
        description: event.target.value
    })
}
const handleCourseLevelChanged = (event) => {
    setNewCourseInfo({
        ...newCourseInfo,
        level: parseInt(event.target.value)
    })
}
const handleCourseThemeChanged = (event) => {
    setNewCourseInfo({
        ...newCourseInfo,
        theme: event.target.value
    })
}
const handleCourseCodeChanged = (event) => {
    setNewCourseInfo({
        ...newCourseInfo,
        code: event.target.value
    })
}
const closeAddCourseModal = () => {
    setAddCourseModalVisible(false);
    setNewCourseInfo({
        title: "",
        description: "",
        level: null,
        code: "",
        theme: "",
        size: 0,
        is_new: true,
    });
    setNewCourseData(null);
}
  const selectCourse = (cid) => {
    const course = courses.find((course) => course.id === cid)
    if(course) {
        setSelectedCourse(course);
    }
  }
  const toggleNew = async (cid, value) => {
    setLoading(true);
    const firestore = getFirestore(fb_app);
    const coursesCollection = collection(firestore, 'courses');
    const docRef = doc(coursesCollection, cid);
    setCourses((prev) => {
        const targetIndex = prev.findIndex((course) => course.id === cid);
        if(targetIndex > -1) {
            const arr = [...prev];
            arr[targetIndex].is_new = value;
            return arr;
            
        }
        return prev
    })
    await updateDoc(docRef, {
        is_new: value
    })
    setLoading(false);
  }
  const toggleHot = async (cid, value) => {
    setLoading(true);
    const firestore = getFirestore(fb_app);
    const coursesCollection = collection(firestore, 'courses');
    const docRef = doc(coursesCollection, cid);
    setCourses((prev) => {
        const targetIndex = prev.findIndex((course) => course.id === cid);
        if(targetIndex > -1) {
            const arr = [...prev];
            arr[targetIndex].is_hot = value;
            return arr;
            
        }
        return prev
    })
    await updateDoc(docRef, {
        is_hot: value
    })
    setLoading(false);
  }
  
  const playURL = async (url) => {
    window.open(url);
  }
  const uploadCourseIntro = (cid) => {
    setUploadIntroModalVisible(true);
    setUploadingIntroCourse(cid);
  }
  const doUploadCourseIntro = async () => {
    const storage = getStorage(fb_app);
    const fileRef = ref(storage, `audios/intro/${uploadingIntroCourse}.mp3`);
    try {
        const snapshot = await uploadBytes(fileRef, introFile);
        const url = await getDownloadURL(snapshot.ref);
        setCourses((prev) => {
            const targetIndex = prev.findIndex((course) => course.id === uploadingIntroCourse);
            if(targetIndex > -1) {
                const arr = [...prev];
                arr[targetIndex].url = url;
                return arr;
            }
            return prev;
        })
    } catch(err) {
        window.alert(err)
    }
    
    closeUploadCourseIntro();
  }
  const closeUploadCourseIntro = () => {
    setIntroFile(null);
    setUploadIntroModalVisible(false);
    setUploadingIntroCourse("");
  }
  const selectTopic = (tid) => {
    const topic = topics.find((topic) => topic.id === tid)
    if(topic) {
        setSelectedTopic(topic);
        setChangedSentences([]);
    }
  }
  const loadTopic = useCallback(async (cid, docRef) => {
    const storage = getStorage(fb_app);
    const { index, sentences, topic } = docRef.data();
    const id = docRef.id;
    let url;
    try {
        url = await getDownloadURL(ref(storage, `audios/intro/${cid}${index}.mp3`));
    } catch(err) {

    }
    const newData = {index, sentences, topic, url, id};
    setTopics((prev) => {
        return [...prev, newData].sort((a, b) => a.index - b.index);
    })
  }, [])
  const loadTopics = useCallback(async (cid) => {
    const firestore = getFirestore(fb_app);

    const topicsCollection = collection(firestore, `courses/${cid}/topics`);
    const topicsQuery = query(topicsCollection, orderBy('index', 'asc'));
    const querySnapshot = await getDocs(topicsQuery);
    for (const docRef of querySnapshot.docs) {
        loadTopic(cid, docRef);
    }
  }, [loadTopic]);

  useEffect(() => {
    if(selectedCourse) {
        loadTopics(selectedCourse.id);
    } else {
        setTopics([]);
    }
  }, [selectedCourse, loadTopics])
  
  
  const handleMouseOver = (index) => {
    if(selectedSentence !== null) return;
    setInterestedSentence(index);
  }
  const handleMouseOut = () => {
    if(selectedSentence !== null) return;
    setInterestedSentence(null);
  }
  const selectSentence = (index) => {
    const sentence = selectedTopic.sentences[index];
    setSelectedSentence(index);
    setSelectedSentenceData({...sentence});
  }
  const saveSentence = (index) => {
    const newData = [...selectedTopic.sentences]
    newData[index] = {...selectedSentenceData};
    setSelectedTopic({
        ...selectedTopic,
        sentences: newData,
    })
    setSelectedSentence(null);
    setChangedSentences((prev) => [...prev, index]);
  }
  const cancelEditSentenece = () => {
    setSelectedSentence(null);
  }
  const handleChangeKor = (event) => {
    setSelectedSentenceData({
        ...selectedSentenceData,
        kor: event.target.value
    })
  }
  const handleChangeEng = (event) => {
    setSelectedSentenceData({
        ...selectedSentenceData,
        eng: event.target.value
    })
  }
  const handleChangePro = (event) => {
    setSelectedSentenceData({
        ...selectedSentenceData,
        pro_kor: event.target.value
    })
  }
  const closeTopic = () => {
    if(changedSentences.length > 0) {
        if(window.confirm("수정사항을 저장하지 않고 나가시겠습니까?")) {
            doClose();
        }
        else {

        }
    } else {
        doClose();
    }
  }
  const saveTopic = () => {
    if(window.confirm("수정사항을 저장하시겠습니까?")) {
        doSave();
    }
  }
  const doClose = () => {
    setChangedSentences([]);
    setSelectedTopic(null);
  }
  const deleteFiles = async (sid) => {
    const storage = getStorage(fb_app);
    const fileRefs = [
        ref(storage, `audios/${sid}-kor-male.mp3`),
        ref(storage, `audios/${sid}-kor-female.mp3`),
        ref(storage, `audios/${sid}-eng-male.mp3`),
        ref(storage, `audios/${sid}-eng-female.mp3`),
    ]
    const promises = []
    await fileRefs.forEach((fileRef) => {
        promises.push(deleteObject(fileRef));
    });
    try {
        await Promise.all(promises);
        console.log("deleted successfully");

    } catch(err) {
        console.log("failed to delete", err);

    }
  }
  const createFiles = async (sid, kor, eng) => {
    try {
        const url = "https://mck4hvafqhb2pa6le5p2mwqy3q0bpkvi.lambda-url.ap-northeast-2.on.aws/tts"
        const response = await fetch(url, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify({ sid, kor, eng }),
            });
        console.log(response.status)
        if (response.ok) {
            console.log("files created successfully")
        }
    } catch(err) {
        console.log(err)
    }
    
  }
  const doSave = async () => {
    const firestore = getFirestore(fb_app);

    setLoading(true);

    const topicDocRef = doc(firestore, `courses/${selectedCourse.id}/topics/${selectedTopic.id}`)
    await updateDoc(topicDocRef, {
        sentences: selectedTopic.sentences
    })
    changedSentences.forEach(async (index) => {
        const sid = `${selectedTopic.id}${index}`;
        const {kor, eng} = selectedTopic.sentences[index]
        await deleteFiles(sid);
        await createFiles(sid, kor, eng);
    })
    setChangedSentences([]);
    setLoading(false);
    window.alert('저장이 완료되었습니다.')
  }
  return (
    <TableContainer component={Paper}>
      {loading && <Spinner/>} {/* Show spinner when isLoading is true */}
      <Table aria-label="Course List">
      <TableHead>
          <TableRow>
            <TableCell>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                Index
              </div>
            </TableCell>
            <TableCell>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                코스명
              </div>
            </TableCell>
            {/* <TableCell>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                Course ID
              </div>
            </TableCell> */}
            <TableCell>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                레벨
              </div>
            </TableCell>
            <TableCell>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                테마
              </div>
            </TableCell>
           
            <TableCell>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                신규
              </div>
            </TableCell>
            <TableCell>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                인기
              </div>
            </TableCell>
            <TableCell>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                소개 음성
              </div>
            </TableCell>
            <TableCell>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                순서 변경
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {courses.map((course, index) => (
            <TableRow key={course.id}>
              <TableCell>{index}</TableCell>
              {/* <TableCell>{course.id}</TableCell> */}
              <TableCell>
                <a style={{ cursor: 'pointer'}} onClick={() => selectCourse(course.id)}>
                    {course.title}
                </a>
              </TableCell>
              <TableCell>
                {levelEditingIndex === index 
                ?
                <input type="number" style={{ flex: 1}} placeholder={course.level} onKeyDown={handleEditingLevel}/>
                :
                <a style={{ cursor: 'pointer'}} onClick={() => {setLevelEditingIndex(index)}}>
                    {course.level}
                </a>
                }
              </TableCell>
              <TableCell>
                {themeEditingIndex === index 
                ?
                <input style={{ flex: 1}} placeholder={course.theme} onKeyDown={handleEditingTheme}/>
                :
                <a style={{ cursor: 'pointer'}}  onClick={() => {setThemeEditingIndex(index)}}>
                    {course.theme}
                </a>
                }
              </TableCell>
              <TableCell>
                <a style={{ cursor: 'pointer'}} onClick={() => toggleNew(course.id, course.is_new ? false : true)}>
                    {course.is_new ?
                    <RadioButtonCheckedIcon/>
                    :
                    <RadioButtonUncheckedIcon/>
                    }
                </a>
              </TableCell>
              <TableCell>
                <a style={{ cursor: 'pointer'}} onClick={() => toggleHot(course.id, course.is_hot ? false : true)}>
                    {course.is_hot ?
                    <RadioButtonCheckedIcon/>
                    :
                    <RadioButtonUncheckedIcon/>
                    }
                </a>
              </TableCell>
              <TableCell>
                {course.url ?
                <a style={{ cursor: 'pointer'}} onClick={() => playURL(course.url)}>
                    <MusicNoteIcon color="primary"/>
                </a>
                :
                <a style={{ cursor: 'pointer'}} onClick={() => uploadCourseIntro(course.id)}>
                    <MusicOffIcon color="disabled"/>
                </a>
                }
              </TableCell>
              <TableCell>
                <div style={{ display: 'flex', flexDirection: 'row', columnGap: 8, alignItems: 'center' }}>
                    <IconButton disabled={index===0} onClick={() => handleChangeCourseIndex(index, 'up')}>
                        <ArrowDropUpIcon />
                    </IconButton>
                    <IconButton disabled={index===courses.length-1} onClick={() => handleChangeCourseIndex(index, 'down')}>
                        <ArrowDropDownIcon />
                    </IconButton>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <HorizontalDiv>
        <div style={{paddingTop: 16, paddingBottom: 16}}>
            <Button
                variant="contained"
                color="primary"
                onClick={() => setAddCourseModalVisible(true)}
            >
                <AddIcon/>
                코스 추가
            </Button>
        </div>
      </HorizontalDiv>
      <Modal isOpen={selectedCourse !==null} onRequestClose={() => setSelectedCourse(null)}
      style={{
        display: 'flex',
        width: 500, height: 500
      }}>
        <IconButton onClick={() => setSelectedCourse(null)} className="close-button">
         <CloseIcon/>
        </IconButton>
        {selectedCourse && 
        <h2>{selectedCourse.title} (레벨: {selectedCourse.level})</h2>
        }
        {topics &&
        <Table aria-label="Topic List">
        <TableHead>
            <TableRow>
                <TableCell>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    Index
                </div>
                </TableCell>
                <TableCell>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    Topic ID
                </div>
                </TableCell>
                <TableCell>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    제목
                </div>
                </TableCell>
                <TableCell>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    인트로 음성
                </div>
                </TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {topics.map((topic, index) => (
                <TableRow key={topic.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{topic.id}</TableCell>
                    <TableCell>
                        <a style={{ cursor: 'pointer'}} onClick={() => selectTopic(topic.id)}>
                            {topic.topic}
                        </a>
                    </TableCell>
                    <TableCell>
                        {topic.url ?
                        <a style={{ cursor: 'pointer'}} onClick={() => playURL(topic.url)}>
                        <MusicNoteIcon color="primary"/>    
                        </a>
                        :
                        <MusicOffIcon color="disabled"/>
                        }
                        
                    </TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        }
      </Modal>
      <Modal isOpen={selectedTopic}
      style={{
        display: 'flex',
        width: 500, height: 500
      }}>
          <IconButton onClick={closeTopic} className="close-button">
            <CloseIcon/>
          </IconButton>
          <IconButton onClick={saveTopic} className="close-button" disabled={changedSentences.length===0}>
            <SaveIcon/>
          </IconButton>
          {selectedTopic && 
          <h2>{selectedTopic.topic}</h2>
          }
          {selectedTopic && selectedTopic.sentences &&
          <Table aria-label="Sentence List">
          <TableHead>
              <TableRow>
                  <TableCell colSpan={1}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                      Index
                  </div>
                  </TableCell>
                  <TableCell colSpan={6}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                      한국어
                  </div>
                  </TableCell>
                  <TableCell colSpan={6}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                      영어
                  </div>
                  </TableCell>
                  <TableCell colSpan={6}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                      발음
                  </div>
                  </TableCell>
                  <TableCell colSpan={2}>
                    <div style={{ display: 'flex', alignItems: 'center' }}/>
                  </TableCell>
              </TableRow>
              </TableHead>
              <TableBody onMouseOut={handleMouseOut}>
              {selectedTopic.sentences.map((sentence, index) => (
                  <TableRow key={index} onMouseOver={(event) => handleMouseOver(index)}
                  style={{
                    backgroundColor: interestedSentence === index ? '#E4EDF1' : 'transparent'
                  }}>
                    <TableCell colSpan={1}>{index + 1}</TableCell>
                    <TableCell colSpan={6}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {selectedSentence === index && selectedSentenceData ? 
                            <input style={{ flex: 1}} value={selectedSentenceData.kor} onChange={handleChangeKor}/>
                            :
                            <p>
                                {sentence.kor}
                            </p>
                            }
                        </div>
                    </TableCell>
                    <TableCell colSpan={6}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {selectedSentence === index && selectedSentenceData ? 
                            <input style={{ flex: 1}} value={selectedSentenceData.eng} onChange={handleChangeEng}/>
                            :
                            <p>
                                {sentence.eng}
                            </p>
                            }
                        </div>
                    </TableCell>
                    <TableCell colSpan={6}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {selectedSentence === index && selectedSentenceData ? 
                            <input style={{ flex: 1}} value={selectedSentenceData.pro_kor} onChange={handleChangePro}/>
                            :
                            <p>
                                {sentence.pro_kor}
                            </p>
                            }
                        </div>
                    </TableCell>
                    <TableCell colSpan={2}>
                        <div style={{ display: 'flex', alignItems: 'center'}}>
                        {selectedSentence === index ?
                            <div>
                                <IconButton onClick={() => saveSentence(index)}>
                                    <SaveIcon/>
                                </IconButton>
                                <IconButton onClick={() => cancelEditSentenece()}>
                                    <CloseIcon/>
                                </IconButton>
                            </div>
                        :
                        interestedSentence === index &&
                        <IconButton onClick={() => selectSentence(index)}>
                            <EditIcon/>
                        </IconButton>
                        }
                        </div>
                    </TableCell>
                  </TableRow>
              ))}
              </TableBody>
          </Table>
          }
      </Modal>
      <Modal isOpen={addCourseModalVisible}>
            <IconButton onClick={closeAddCourseModal} className="close-button">
                <CloseIcon/>
            </IconButton>
            <div style={{
                width: '100%',
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}>
                <input type="file" accept=".csv" onChange={handleFileChange} />
                <Button
                 variant="contained"
                 color="primary"
                 disabled={newCourseData === null}
                 onClick={addCourse}
                >
                    추가
                </Button>
            </div>
            <div>
                <TextField id="standard-title" fullWidth label="제목" variant="standard" value={newCourseInfo.title} onChange={handleCourseTitleChanged} disabled={newCourseData === null}/>
                <TextField id="standard-desc" fullWidth label="설명" variant="standard" value={newCourseInfo.description} onChange={handleCourseDescChanged} disabled={newCourseData === null}/>
                <TextField id="standard-level" fullWidth label="레벨" variant="standard" type="number" value={newCourseInfo.level} onChange={handleCourseLevelChanged} disabled={newCourseData === null}/>
                <TextField id="standard-theme" fullWidth label="테마" variant="standard" value={newCourseInfo.theme} onChange={handleCourseThemeChanged} disabled={newCourseData === null}/>
                <TextField id="standard-code" fullWidth label="코드" variant="standard" value={newCourseInfo.code} onChange={handleCourseCodeChanged} disabled={newCourseData === null}/>
            </div>
            {newCourseData &&
            <div>
                <h4>
                    데이터
                </h4>
                <pre>{JSON.stringify(newCourseData, null, 2)}</pre>
            </div>
            }
        </Modal>
        <Modal isOpen={uploadIntroModalVisible}
            style={{
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: 'auto',  // 컨텐츠 너비에 맞춤
                height: 'auto', // 컨텐츠 높이에 맞춤
                overflow: 'visible', // 필요한 경우 컨텐츠가 모달 밖으로 넘칠 수 있게 설정
                display: 'flex',
                flexDirection: 'column',
                rowGap: 16,
            } 
          }}>
            <span>인트로 음성 업로드</span>
            <input type="file" accept=".mp3" onChange={handleIntroFileChange} />
            <Button
            variant="contained"
            color="primary"
             onClick={doUploadCourseIntro}
             disabled={introFile === null}
             >
                업로드
            </Button>
            <Button
            variant="contained"
            color="inherit"
             onClick={closeUploadCourseIntro}>
                닫기
            </Button>
        </Modal>
    </TableContainer>
  );
};

export default CourseListTable;
